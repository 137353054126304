import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function getBlogPostAsync({
	id,
	impersonationContext,
	userSession,
}: {
	id: string;
	impersonationContext?: Api.IImpersonationContext;
	userSession: Api.UserSessionContext;
}) {
	return userSession.webServiceHelper.callAsync<Api.IBlogPost>(
		Api.ImpersonationBroker.composeApiUrl({
			impersonationContext,
			urlPath: `blog/${id}`,
		}),
		'GET',
		null
	);
}

interface Params {
	post: Api.IBlogPost;
}
export function useBlogUpdateScheduleMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: Api.IBlogPost) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ post }: Params) => {
			return userSession.webServiceHelper.callAsync<Api.IBlogPost>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `blog/schedule`,
					queryParams: {
						forUserId: post.sendFromUserId,
					},
				}),
				'PUT',
				post
			);
		},
		onError,
		onSuccess,
	});
}
