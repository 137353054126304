import { useQuery } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = `social/post/contentCalendar/pendingApproval`;

export function useSocialContentCalPendingApprovalQuery(
	params: { enabled?: boolean; impersonationContext?: Api.IImpersonationContext } = {}
) {
	const userSession = useUserSession();
	return useQuery({
		enabled: params.enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<number>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { includeAllAdminOptions: true },
					urlPath: `social/post/contentCalendar/pendingApproval`,
					impersonationContext: params.impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY],
	});
}

export function invalidateSocialContentCalPendingApprovalQuery() {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
}
