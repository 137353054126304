import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'template';

export function useTemplate<T extends Api.ITemplate>({
	templateId,
	enabled,
}: {
	templateId: string;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<T>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `template/${encodeURIComponent(templateId)}` }),
				'GET'
			),
		queryKey: [BASE_QUERY_KEY, { templateId }],
	});
}
